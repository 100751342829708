<template>
    <FontAwesomeIcon
        class="icon-error"
        :icon="['fas', 'circle-check']"
    ></FontAwesomeIcon>
</template>
<style lang="scss" scoped>
    .icon-error {
        margin: 0 0.5rem 0 0;
        color: $caribbean-green;
        transform: scale(0.75);
    }
</style>
