import { App } from 'vue';
import { setupValidation } from './validation';
import selfXssWarning from '@tipalti/self-xss-warn';
import Toast, { POSITION } from 'vue-toastification';

export function setupGlobalComponents(app: App): void {
    selfXssWarning();
    app.use(Toast, {
        position: POSITION.TOP_CENTER,
        timeout: 0,
        shareAppContext: true,
    });
    setupValidation();
}

