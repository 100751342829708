<template>
    <div
        class="login-app"
        :class="{
            'i18next-ready': $i18next?.isInitialized,
        }"
    >
        <div
            v-if="!initialized"
            class="login-app__tip-spinner"
        >
            <TipSpinner />
        </div>
        <RouterView
            v-if="initialized"
            v-slot="{ Component, route }"
        >
            <Component
                :is="Component"
                :key="route.path"
                class="login-app__component"
            />
        </RouterView>
    </div>
</template>

<script lang="ts">
    // *global styles
    // *should be refactored to the scope of each component where possible
    import './style';
    import notificationService from './services/notificationService';
    import sessionData from '@tipalti/session-data';
    import securityConstants from './consts/security';
    import { defineComponent, ref } from 'vue';
    import TipSpinner from './components/TipSpinner.vue';

    export default defineComponent({
        name: 'App',
        components: {
            TipSpinner,
        },
        setup() {
            const initialized = ref(false);

            return {
                initialized,
            };
        },
        data() {
            return {
                configData: {},
            };
        },
        async mounted() {
            // this breaks the tests
            /* istanbul ignore next */
            // Removal of this is punishable - don't do it!
            // We need this for language and marketing banner etc.
            await this.$nextTick();
            this.initialize();
        },
        methods: {
            initialize() {
                // initAnalytics();
                this.initialized = true;
                const { logoutReasonKey } = securityConstants.sessionData;
                const logoutReason = sessionData.getSessionDataItem(logoutReasonKey);

                if (logoutReason) {
                    notificationService.error(logoutReason);
                    sessionData.removeSessionDataItem(logoutReasonKey);
                }
            },
        },
    });
</script>
<style lang="scss">
    #app {
        width: 100vw;
        min-height: 100%;
        display: flex;
        align-items: stretch;
        justify-content: stretch;
    }

    @media (max-width: 992px) {
        .login-app__iframe {
            display: none;
        }
    }

    .login-app {
        display: flex;
        flex: 1;
        align-items: stretch;
        justify-content: stretch;

        [class^='login-app__type-'],
        .login-app--dev-hub {
          flex: 1;
        }

        &__tip-spinner {
            display: flex;
            flex: 1;
            align-items: center;
            justify-content: center;
            height: 100%;
        }

        &__wrapper {
            display: flex;
            align-items: stretch;
            justify-content: stretch;
            flex: 1;
            min-height: 100%;
        }

        &__iframe {
            flex: 1;
            overflow: hidden;

            iframe {
                width: 100%;
                height: 100%;
                border: 0;
            }
        }

        &__content {
            display: flex;
            flex: 1;
            flex-direction: column;
            align-content: center;
            align-items: center;
            justify-content: center;
        }

        &__view {
            flex: 2;
            width: 20.625rem;
        }

        &__logo {
            width: 11.25rem;
            height: 5.625rem;
        }

        &__component {
            flex: 1;
            display: flex;
            justify-content: stretch;
            align-items: stretch;
        }

        &__header {
            padding-top: 3.5rem;
        }

        &__header,
        &__footer {
            display: flex;
            flex: 1;
            flex-direction: column;
            justify-content: flex-end;
            text-align: center;
        }
    }

    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 2s ease;
    }

    .fade-enter-from,
    .fade-leave-to {
        opacity: 0.01; // leave as 0.01 so Lighthouse and other tools respect it's presence
    }
</style>
