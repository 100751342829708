/* eslint-disable no-console */
import * as Sentry from '@sentry/vue';
import { AxiosError } from 'axios';

type SentryLevel = 'fatal' | 'error' | 'warning' | 'log' | 'info' | 'debug';

/**
 * should log the log levels listed in the array
 * @param level
 */
export const shouldLogBySeverity = (level: SentryLevel): boolean =>
    (['error', 'fatal'] as string[]).includes(level);

/**
 * should NOT log the error statuses listed in the array
 * @param error
 */
export const shouldLogByErrorStatus = (error: Error | AxiosError | unknown): boolean =>
    !(
        [
            // Reverted INFRA-6185
            // 400,
            // 401,
        ] as number[]
    ).includes((error as AxiosError)?.response?.status || -1);

export const getConsoleMethod = (level: SentryLevel = 'log'): Function => {
    const consoleMethods: Record<SentryLevel, Function> = {
        fatal: console.error,
        error: console.error,
        warning: console.warn,
        info: console.info,
        debug: console.debug,
        log: console.log,
    };
    return consoleMethods[level] || console.log;
};

const logToConsole = (level: SentryLevel, message: Error | unknown) => {
    const log = getConsoleMethod(level);
    log(message);
};

const logToSentry = (level: SentryLevel, message: string) => {
    if (shouldLogBySeverity(level)) {
        Sentry.captureMessage(message, level);
    } else {
        logToConsole(level, message);
    }
};

export default {
    fatal: (message: string) => {
        logToSentry('fatal', message);
    },
    error: (error: Error | unknown) => {
        if (shouldLogBySeverity('error') && shouldLogByErrorStatus(error)) {
            if (!(error instanceof Error)) {
                error = new Error(JSON.stringify(error, null, 2));
            }
            Sentry.captureException(error);
        } else {
            logToConsole('error', error);
        }
    },
    warn: (message: string) => {
        logToSentry('warning', message);
    },
    info: (message: string) => {
        logToSentry('info', message);
    },
    debug: (message: string) => {
        logToSentry('debug', message);
    },
    log: (message: string) => {
        logToSentry('log', message);
    },
    setUser: (user: Sentry.User | Record<string, unknown> | null) => {
        Sentry.setUser(user);
    },
    setContext: (name: string, context: Record<string, unknown> | null) => {
        Sentry.setContext(name, context);
    },
    configureScope: (callback: (scope: Sentry.Scope) => void) => {
        Sentry.configureScope(callback);
    },
};
