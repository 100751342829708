export default {
    apiUrls: {
        Configuration: '/gateway/aphub/api/v0/dashboard/configuration',
        Login: '/gateway/identityserver/api/v1/Authorization/AuthenticateUserPassword',
        LoginUserName: '/gateway/identityserver/api/v1/Authorization/InitializeLoginByUserFlow',
        initializeLoginProcess: '/gateway/identityserver/api/v1/Authorization/InitializeLoginProcess',
        initializeLoginByUserFlow: '/gateway/identityserver/api/v1/Authorization/InitializeLoginByUserFlow',
        authenticateUserPassword: '/gateway/identityserver/api/v1/Authorization/AuthenticateUserPassword',
        canSubscribe: '/gateway/identityserver/api/v1/ThirdPartyApp/CanSubscribe',
        getPayersForCurrentLoginStateUser:
            '/gateway/identityserver/api/v1/PayerManagement/GetPayersForCurrentLoginStateUser',
        createAndSigninApplicativeUser: '/gateway/identityserver/api/v1/PayerManagement/CreateAndSigninApplicativeUser',
        getScopesByLoginState: '/gateway/identityserver/api/v1/LoginClientScope/GetScopesByLoginState',
        grantConsent: '/gateway/identityserver/api/v1/Consent/GrantConsent',

        // Account Service
        registerUser: '/gateway/identityserver/api/v1/user/register-new-payer-user',
        changePassword: '/gateway/identityserver/api/v1/user/change-expired-password',
        resetPassword: '/gateway/identityserver/api/v1/user/reset-password',
        resetPasswordLink: '/gateway/identityserver/api/v1/user/send-reset-password-link',
        getResetPasswordSecurityQuestion: '/gateway/identityserver/api/v1/user/get-reset-password-security-question',

        // User Registration
        validateToken: '/gateway/aphub/api/v0/UserRegistration/ValidateToken',
        termsOfUse: '/gateway/aphub/api/v0/UserRegistration/GetLatestTipaltiTermsOfUse',
    },
    consentUrls: {
        payerList: 'consent/select-payers',
        missingPermission: 'consent/missing-permission',
        consentScopes: 'consent/scopes',
        consentStartUrl: '/login/dev-hub/consent/select-payers',
    },
    twoFactor: {
        apiUrls: {
            registerUser: '/gateway/identityserver/api/v1/login/two-factor/register-new-user',
            countryCodes: '/gateway/identityserver/api/v1/login/two-factor/country-codes',
            sendPinCode: '/gateway/identityserver/api/v1/login/two-factor/send-pin-code',
            validatePinCode: '/gateway/identityserver/api/v1/login/two-factor/validate-pin-code',
            resendCode: '/gateway/identityserver/api/v1/login/two-factor/resend-code',
            getSetupInfo: '/gateway/identityserver/api/v1/TwoFactorAuthentication/GetAuthenticatorSetupInfo',
            validateTwoFactorCode: '/gateway/identityserver/api/v1/TwoFactorAuthentication/ValidateTwoFactorCode',
        },
        statuses: {
            newUser: 'NewUser',
            existingUser: 'ExistingUser',
        },
        childStates: {
            newUser: 'new-user',
            validateUser: 'validate-user',
        },
        firstLoginTitle: '2-step verification',
        title: 'Authentication',
        titleTooltip:
            '2-step verification makes your account more secure by verifying your identity from two distinct sources.',
        verifyButton: 'Verify',
        verifyButtonLoading: 'Verifying...',
        setupInstruction: 'To enable authentication, use the Google Authenticator app to:',
        pinCodeTextFirstLogin:
            'When you’re done, the Google authenticator app will provide you with a unique verification code which you should enter into the box below',
        pinCodeText: 'Enter the Google Authenticator code into the box below',
        invalidPinCodeText: 'The code is incorrect or has expired',
        pinCodePlaceholder: 'Enter code here',
        scanQRCode: 'Scan this QR code',
        manualCode: 'Enter this keycode',
        or: 'Or',
        getAuthenticatorSetupInfoError:
            'Failed to get two factor authentication settings, please try again.',
        validatePinCodeError: 'Failed to validate pin code, please try again.',
        minPinCodeLength: 6,
        pinCodeValidationStatus: {
            success: 1,
            invalidPinCode: 2,
            userIsLocked: 3,
            generalError: 4,
        },
    },
    ssoConfigurations: {
        mandatory: 'Mandatory',
    },
    sources: {
        loginPassword: 'password',
        forgotPassword: 'forgot-password',
        passwordExpired: 'password-expired',
        registerUser: 'register-user',
        loginFailed: 'login-failed',
    },
    selectLanguage: {
        localeEnabled: 'LocaleEnabled',
        unauthenticatedUser: '$Tipalti_Unauthenticated_User'
    },
    types: {
        admin: 'admin',
        hub: 'hub',
        devHub: 'dev-hub'
    },
    loginApplicationNames: {
        devPortalAlt: 'DevPortalAlt',
        thirdPartyApp: 'ThirdPartyApp',
    },
    storage: {
        rememberMe: 'rememberMe',
        loginType: 'loginType',
        oidcReturnUrl: 'oidcReturnUrl',
        routeErrorMessage: 'routeErrorMessage',
    },
    errorCodes: {
        invalidReturnURL: 'INVALID_RETURN_URL',
        invalidCredentials: 'INVALID_CREDENTIALS',
        invalidState: 'INVALID_STATE',
        passwordExpired: 'PASSWORD_EXPIRED',
        complexity: 'PASSWORD_NEED_MORE_NON_ALPHA_NUMERIC_CHARS',
        wrongAnswer: 'WRONG_ANSWER',
        tooManyOtpVerificationAttempts: 'TOO_MANY_OTP_VERIFICATION_ATTEMPTS',
        wrongCode: 'WRONG_CODE',
        userLocked: 'USER_LOCKED',
    },
    context: {
        login: 'login',
    },
};

// https://sso-dev.clstr04.payrad.com/login/admin?
//
// oidcReturnUrl
// http%3A%2F%2Fsso-dev.clstr04.payrad.com%2Fconnect%2Fauthorize%2Fcallback%3Fresponse_type%3Dcode%26state%3Dvqgfp358jqO-oNWuJIzFyg%26code_challenge%3DWMe7geiprq5JWSfhf-RaOCFjtUiwnriAg0eW8MQLkx0%26code_challenge_method%3DS256%26client_id%3Dlogin%26scope%3Dbasicapi%2520openid%2520offline_access

// redirect_uri
// https%253A%252F%252Foperations-admin-dev.clstr04.payrad.com%252Fgtw%252FOperationsAdmin%252Fapi%252FV1%252FLogin%252FAuthorizeSso#/login-app
