import { createRouter, createWebHistory } from 'vue-router';
import loginConsts from '../consts/login/loginConsts';
import logger from './logger';
import RouteNames from '../lib/route-names';
import configService from '../services/configService';

const Components = {
    LoginWrapper: () => import('../views/LoginWrapper.vue'),
    LoginUserName: () => import('../views/login/LoginUserName.vue'),
    LoginPassword: () => import('../views/login/LoginPassword.vue'),
    LoginSSO: () => import('../views/login/LoginSSO.vue'),
    ForgotPassword: () => import('../views/forgotPassword/ForgotPassword.vue'),
    ForgotPasswordSent: () => import('../views/forgotPassword/ForgotPasswordSent.vue'),
    ResetPassword: () => import('../views/resetPassword/ResetPassword.vue'),
    RegisterUser: () => import('../views/registerUser/RegisterUser.vue'),
    PasswordExpired: () => import('../views/passwordExpired/PasswordExpired.vue'),
    TwoFactorNewUser: () => import('../views/twoFactor/TwoFactorNewUser.vue'),
    TwoFactorValidateUser: () => import('../views/twoFactor/TwoFactorValidateUser.vue'),
    TwoFactorSSO: () => import('../views/twoFactor/TwoFactorSSO.vue'),
    LoginFailed: () => import('../views/login/LoginFailed.vue'),
    PageNotFound: () => import('../views/pageNotFound/PageNotFound.vue'),
    LandingPage: () => import('../views/landingPage/LandingPage.vue'),
    MissingPermission: () =>
        import('../views/consent/missingPermission/AppSubscriberMissingPermission.vue'),
    SelectPayerEntities: () => import('../views/consent/selectPayer/SelectPayerEntities.vue'),
    ConsentScopes: () => import('../views/consent/scopes/ConsentScopes.vue'),
};

const isValidType = (type: string): boolean =>
    !!type && Object.values(loginConsts.types).includes(type);

const router = createRouter({
    history: createWebHistory(),
    routes: [
        { path: '/', redirect: '/login' },
        {
            path: '/login',
            component: Components.LandingPage,
        },
        {
            path: '/login/:type',
            component: Components.LoginWrapper,
            props: (route) => ({
                type: route.params.type,
            }),
            beforeEnter: async (to, from, next) => {
                // validate type in route guard against valid types, show error page if invalid
                if (!isValidType(to.params.type as string)) {
                    logger.log(`Route path [${to.path}] not found`);
                    console.error(`Route path [${to.path}] not found`);
                    await router.push(`/page-not-found`);
                }
                next();
            },
            // TODO: save type in route entry, etc. in case of refresh or back button, or something of the sort
            children: [
                // {
                //     name: 'sso',
                //     path: 'sso',
                //     redirect: (route) => {
                //        return route.params.redirect_uri
                //     }
                // },
                { path: 'login-username', redirect: 'username' },
                {
                    // TODO: jump to second step for admin type in route guard
                    name: RouteNames.username,
                    alias: [''],
                    path: 'username',
                    component: Components.LoginUserName,
                    props: (route) => {
                        return {
                            billApproverToken: route.query.billApproverToken,
                            username:
                                route.query.username ||
                                window.loginInformation?.userNameLoginFromCookie, // getSystemConfiguration
                            source: route.query.source,
                            type: route.params.type,
                        };
                    },
                    meta: {
                        showFooter: true,
                        showMarketersBlock: true,
                    },
                },
                { name: 'login-password', path: 'login-password', redirect: 'password' },
                {
                    name: RouteNames.password,
                    path: 'password',
                    component: Components.LoginPassword,
                    beforeEnter: async (to, from, next) => {
                        // TODO: any is bad
                        const config: Record<string, any> = await configService.getConfig();
                        if (from.name !== RouteNames.username && config?.hubUrl) {
                            window.location.assign(config.hubUrl);
                        }
                        next();
                    },
                    props: (route) => {
                        return {
                            billApproverToken: route.query.billApproverToken,
                            ssoOnly: route.query.isSsoFlow === 'true',
                            username:
                                route.query.username ||
                                window.loginInformation?.userNameLoginFromCookie,
                            redirectSsoUrl: route.query.redirectSsoUrl,
                        };
                    },
                    meta: {
                        showFooter: true,
                        showMarketersBlock: true,
                    },
                },
                {
                    name: RouteNames.sso,
                    path: 'sso',
                    component: Components.LoginSSO,
                    props: (route) => {
                        return {
                            redirectSsoUrl: route.query.redirectSsoUrl,
                        };
                    },
                    meta: {
                        showFooter: true,
                        showMarketersBlock: false,
                    },
                },
                {
                    name: RouteNames.forgotPassword,
                    path: 'forgot-password',
                    component: Components.ForgotPassword,
                    props: (route) => ({
                        routeErrorMessage: route.query.errorMessage,
                    }),
                    meta: {
                        showFooter: true,
                        showMarketersBlock: false,
                    },
                },
                {
                    name: RouteNames.forgotPasswordSent,
                    path: 'forgot-password-sent',
                    component: Components.ForgotPasswordSent,
                    props: (route) => ({
                        email: route.query.email,
                    }),
                    meta: {
                        showFooter: true,
                        showMarketersBlock: false,
                    },
                },
                {
                    name: RouteNames.resetPassword,
                    path: 'reset-password',
                    component: Components.ResetPassword,
                    props: (route) => ({
                        token: route.query.token,
                        username: route.query.username,
                    }),
                    meta: {
                        showFooter: true,
                    },
                },
                { name: 'register', path: 'register', redirect: 'register-user' },
                {
                    name: RouteNames.registerUser,
                    path: 'register-user',
                    component: Components.RegisterUser,
                    props: (route) => ({
                        billApproverToken: route.query.billApproverToken,
                        token: route.query.token,
                    }),
                    meta: {
                        showFooter: true,
                    },
                },
                { name: 'expired', path: 'expired', redirect: 'password-expired' },
                {
                    name: RouteNames.passwordExpired,
                    path: 'password-expired',
                    component: Components.PasswordExpired,
                    props: (route) => ({
                        username: route.query.username,
                    }),
                    meta: {
                        showFooter: true,
                    },
                },
                { path: 'twoFactor/newUser', redirect: 'two-factor/sms/new-user' },
                {
                    // TODO: based on type / config redirect to token or sms
                    name: RouteNames.twoFactorSmsNewUser,
                    path: 'two-factor/sms/new-user',
                    component: Components.TwoFactorNewUser, // TODO: rename as these are sms specific
                    props: (route) => {
                        return {
                            previousDialCode: route.query.previousDialCode,
                            isMobileNumberManagementImprovementEnabled:
                                route.query.isMobileNumberManagementImprovementEnabled,
                        };
                    },
                    meta: {
                        showFooter: true,
                        showMarketersBlock: true,
                    },
                },
                { path: 'twoFactor/validateUser', redirect: 'two-factor/sms/validate-user' },
                {
                    // TODO: based on type / config redirect to token or sms
                    name: RouteNames.twoFactorSmsValidateUser,
                    path: 'two-factor/sms/validate-user',
                    component: Components.TwoFactorValidateUser, // TODO: rename as these are sms specific
                    props: (route) => ({
                        userModel: {
                            mobileNumber: route.query.mobileNumber,
                            resendCodeCount: route.query.resendCodeCount,
                            attemptsCount: route.query.attemptsCount,
                            lastSendTime: route.query.lastSendTime,
                            isNewUser: route.query.isNewUser,
                            isVerified: route.query.isVerified,
                            isMobileNumberManagementImprovementEnabled:
                                route.query.isMobileNumberManagementImprovementEnabled,
                        },
                    }),
                    meta: {
                        showFooter: true,
                        showMarketersBlock: true,
                    },
                },
                {
                    name: RouteNames.twoFactorSSO,
                    path: 'two-factor/sso',
                    component: Components.TwoFactorSSO,
                    meta: {
                        showFooter: true,
                        showMarketersBlock: false,
                    },
                },
                {
                    name: RouteNames.consentPayerList,
                    path: loginConsts.consentUrls.payerList,
                    component: Components.SelectPayerEntities,
                    meta: {
                        showFooter: true,
                        showMarketersBlock: false,
                    },
                },
                {
                    name: RouteNames.consentMissingPermission,
                    path: loginConsts.consentUrls.missingPermission,
                    component: Components.MissingPermission,
                    meta: {
                        showFooter: true,
                        showMarketersBlock: false,
                    },
                },
                {
                    name: RouteNames.consentScopes,
                    path: loginConsts.consentUrls.consentScopes,
                    component: Components.ConsentScopes,
                    meta: {
                        showFooter: true,
                        showMarketersBlock: false,
                    },
                },
            ],
        },
        {
            path: '/internal/two-factor',
            redirect: '/login/admin/two-factor/sso',
        },
        {
            name: RouteNames.loginFailed,
            path: '/login-failed',
            component: Components.LoginFailed,
        },
        {
            name: RouteNames.pageNotFound,
            path: '/page-not-found',
            component: Components.PageNotFound,
            beforeEnter: async (to, from, next) => {
                const config: Record<string, unknown> = await configService.getConfig();
                if (config?.hubUrl) {
                    window.location.assign(config.hubUrl as string);
                }
                next();
            },
        },
        {
            name: RouteNames.catchAll,
            path: '/:catchAll(.*)',
            redirect: (route) => {
                logger.log(`Route path [${route.path}] not found`);
                console.error(`Route path [${route.path}] not found`);
                return '/page-not-found';
            },
        },
    ],
});

export default router;
