export default {
    username: 'username',
    password: 'password',
    sso: 'sso',
    forgotPassword: 'forgot-password',
    forgotPasswordSent: 'forgot-password-sent',
    resetPassword: 'reset-password',
    registerUser: 'register-user',
    passwordExpired: 'password-expired',
    twoFactorSmsNewUser: 'two-factor.new-user',
    twoFactorSmsValidateUser: 'two-factor.validate-user',
    twoFactorSSO: 'two-factor.sso',
    consentPayerList: 'consent.payer-list',
    consentMissingPermission: 'consent.missing-permission',
    consentScopes: 'consent.scopes',
    loginFailed: 'login-failed',
    pageNotFound: 'page-not-found',
    catchAll: 'catch-all',
};