// font awesome svg icons
import { App } from 'vue';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faSpinner, faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';
import { faBan } from '@fortawesome/pro-solid-svg-icons/faBan';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons/faCircleCheck';
import { faInfoCircle } from '@fortawesome/pro-solid-svg-icons/faInfoCircle';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faCheckCircle, faInfoCircle as faInfoCircleLight } from '@fortawesome/pro-light-svg-icons';
import { faCheck, faAngleLeft } from '@fortawesome/pro-regular-svg-icons';

export function setupFontAwesome(app: App): void {
    // to use icons they must be registered
    library.add(faSpinner);
    library.add(faSpinnerThird);
    library.add(faCircleCheck);
    library.add(faInfoCircle);
    library.add(faBan);
    library.add(faCheckCircle);
    library.add(faInfoCircleLight);
    library.add(faCheck);
    library.add(faAngleLeft);
    app.component('FontAwesomeIcon', FontAwesomeIcon);
}

export default { setupFontAwesome };
