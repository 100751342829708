import { i18nInstance, ResourcesType, TipaltiI18n } from '@tipalti-aphub/i18n';
import I18NextVue from 'i18next-vue';
import { App } from 'vue';
import { isDevelopmentEnv } from '../lib/env';
import configService from '../services/configService';

import enLogin from '../../../../../../tipalti-translations/bundles/login/en/default/login.json';

import { HubNamespaces, TipaltiLocale, TranslationBundles } from '@tipalti/web-constants';
import { WebConfigOptionArgs } from "@tipalti-aphub/web-config/src/web-config";

const loginNamespace = HubNamespaces.login;
const commonNamespace = HubNamespaces.common;

export const DEFAULT_LOCALE: TipaltiLocale = TipaltiLocale.English;
    
let i18next: i18nInstance;
let tipaltiI18n: TipaltiI18n;

const namespaces: string[] = [commonNamespace, loginNamespace];

const webConfigOptionArgs: WebConfigOptionArgs = {
    domain: 'login',
    apiEndpoint: '/gateway/site-config',
} as WebConfigOptionArgs;

let resources: ResourcesType = {
    [TipaltiLocale.English]: {
        login: enLogin,
    },
} as ResourcesType;

async function initI18n(): Promise<i18nInstance> {
    const config: Record<string, any> = await configService.getConfig();
    const domain = config.translationServiceUrl;
    tipaltiI18n = new TipaltiI18n(domain, undefined, TranslationBundles.login);
    tipaltiI18n.namespaces = namespaces;

    resources = await tipaltiI18n.getTranslations(
        TranslationBundles.login,
        DEFAULT_LOCALE,
        namespaces,
        resources,
        webConfigOptionArgs
    );

    return await tipaltiI18n.initI18next(
        {
            debug: isDevelopmentEnv(),
            ns: loginNamespace,
            defaultNS: loginNamespace,
            fallbackNS: commonNamespace,
            nonExplicitSupportedLngs: true,
            resources
        },
        webConfigOptionArgs
    );
}

export async function setupI18n(app: App): Promise<void> {
    i18next = await initI18n();
    app.use(I18NextVue, { i18next });
}

export function getI18NextInstance(): i18nInstance {
    if (i18next) {
        return i18next;
    }

    throw new Error('i18next instance is not initialized - try running initI18n() first');
}

export { i18next, tipaltiI18n };