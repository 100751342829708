import IconError from '../components/IconError.vue';
import IconSuccess from '../components/IconSuccess.vue';
import { useToast } from 'vue-toastification';

// TODO - use i18n
function error(text: string) {
    const toast = useToast();
    toast.error(text, {
        icon: IconError,
        timeout: 5000,
    });
}

// TODO - use i18n
function success(text: string) {
    const toast = useToast();
    toast.success(text, {
        icon: IconSuccess,
        timeout: 5000,
    });
}

export default {
    error,
    success,
};
